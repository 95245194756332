import React from "react"
import { useState, setState } from "react"
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import ProductArchive from "../components/product-archive"
import { Container, Col, Row, Form } from "react-bootstrap"

export const query = graphql`
query($categoryName: String, $brandName: String) {
  placeholder: file(relativePath: {eq: "placeholder.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 600
        placeholder:BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
allWpSimpleProduct(
    filter: {
        productCategories: {nodes: {elemMatch: {name: {glob: $categoryName}}}},
        allPaBrand: {nodes: {elemMatch: {name: {glob: $brandName}}}},
        stockQuantity: {gt: 0}
    }
  ) {
    totalCount
    nodes {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 450, placeholder: BLURRED, formats: AUTO)
          }
        }
      }
      name
      slug
      price
      salePrice
      onSale
      regularPrice
      allPaBrand {
        nodes {
          name
        }
      }
    }
  }
  allWpPaBrand(
    filter: {
      products: {nodes: {elemMatch: {allPaBrand: {nodes: {elemMatch: {name: {glob: $brandName}}}}, 
      productCategories: {nodes: {elemMatch: {name: {glob: $categoryName}}}}}}}
    }
  ) {
    nodes {
      name
      products {
        nodes {
          id
        }
      }
    }
  }
}`

const Shop = ({ data: {
  allWpSimpleProduct: { totalCount, nodes },
  placeholder
},
}) => {
  const [filteredItems, setFilteredItems] = useState(nodes)
  const resetPage = React.createRef();

  const getPage = items => {
    let pageArray = []
    for (let i = 1; i < (items / 12); i++) {
        pageArray.push(i)
    }
    return pageArray
}

  function handleFilter() {
    let filterArray = nodes
    filterArray = handleBrands(filterArray)
    filterArray = handleSale(filterArray)
    filterArray = handlePrice(filterArray)
    setFilteredItems(filterArray)
    resetPage.current.changePage(0)
    resetPage.current.setState({pagination: getPage(filterArray.length + 12)})
  }

  const brands = nodes.map(item => {
    return item.allPaBrand.nodes[0].name
  })

  const uniqueBrands = [...new Set(brands)]

  function countBrands(e) {
    const brandArray = brands.filter(item => {
      return item === e
    })
    return brandArray.length
  }

  function handleSale(items) {
    const saleCheck = document.getElementById("onSale")
    if (saleCheck.checked) {
      return items.filter(product => product.onSale === true)
    } else {
      return items
    }
  }

  function handleBrands(items) {
    const elems = document.querySelector("#brandsForm").elements
    let list = []
    for (var i = 0; i < elems.length; i++) {
      if (elems[i].checked) {
        list.push(elems[i].value)
      }
    }
    if (list.length > 0) {
      return items.filter(product => list.includes(product.allPaBrand.nodes[0].name))
    } else {
      return items.filter(product => !list.includes(product.allPaBrand.nodes[0].name))
    }
  }
  function handlePrice(items) {
    const elems = document.querySelector("#priceForm").elements
    let list = []
    for (var i = 0; i < elems.length; i++) {
      if (elems[i].checked) {
        list.push(elems[i].dataset.lowPrice, elems[i].dataset.highPrice)
      }
    }
    console.log(list)
    if (list.length > 0) {
      const min = Math.min(...list)
      const max = Math.max(...list)
      return items.filter(product => Number(product.price.replace(/[^0-9.-]+/g, "")) > min && Number(product.price.replace(/[^0-9.-]+/g, "")) < max)
    } else {
      return items.filter(product => Number(product.price.replace(/[^0-9.-]+/g, "")) > 0)
    }
  }
  return (
    
    <Layout>
      <Container>
        <Row>
          <Col md={3}>
            <h3 className={`fw-light mt-4`}>Product Filters</h3>
            <h4 className={`mt-4`}>Shop by Brand</h4>
            <Form id="brandsForm">
              {uniqueBrands.map(brand => {
                return (
                  <div className={`d-flex`}>
                  <Form.Check
                    type="checkbox"
                    label={`${brand}`}
                    id={`${brand}`}
                    value={brand}
                    class="brands"
                    name={`brand`}
                    onChange={handleFilter}
                  />
                  <p className={`text-muted mb-0`}>&nbsp;({countBrands(brand)})</p>
                  </div>
                )
              })}
            </Form>
            <h4 className={`mt-4`}>On Sale</h4>
            <Form id="saleForm">
              <Form.Check
                type="checkbox"
                label="On Sale"
                id="onSale"
                onChange={handleFilter}
              />
            </Form>
            <h4 className={`mt-4`}>Shop by Price</h4>
            <Form id="priceForm">
              <Form.Check
                type="checkbox"
                label="Under $250"
                id="under250"
                name="price"
                data-low-price="0"
                data-high-price="249.99"
                onChange={handleFilter}
              />
              <Form.Check
                type="checkbox"
                label="$250 to $499.99"
                id="250to499"
                name="price"
                data-low-price="250"
                data-high-price="499.99"
                onChange={handleFilter}
              />
              <Form.Check
                type="checkbox"
                label="$500 to $999.99"
                id="500to999"
                name="price"
                data-low-price="500"
                data-high-price="999.99"
                onChange={handleFilter}
              />
              <Form.Check
                type="checkbox"
                label="$1000+"
                id="1000plus"
                name="price"
                data-low-price="1000"
                data-high-price="100000"
                onChange={handleFilter}
              />
            </Form>
          </Col>
          <Col md={9}>
            <ProductArchive ref={resetPage} products={filteredItems} count={totalCount} widgetTitle={`Products`} placeholder={placeholder} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export default Shop